var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',[_c('div',{staticClass:"flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap"},[_c('h2',{staticClass:"is-size-5"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.mainCards.bonus.header.title"))+" ")]),_c('div',{staticClass:"flex is-flex-wrap-wrap"},[_vm._l((_vm.periods),function(ref,idx){
var value = ref.value;
return [_c('b-radio-button',{key:idx,staticClass:"ml-2",attrs:{"native-value":value,"type":"is-info is-light"},model:{value:(_vm.bonusPeriod),callback:function ($$v) {_vm.bonusPeriod=$$v},expression:"bonusPeriod"}},[_vm._v(" "+_vm._s(_vm.$t(("admin.dashboard.mainCards.bonus.header." + value)))+" ")])]})],2)]),_c('div',{staticClass:"is-relative"},[_c('p',{staticClass:"is-size-4 has-text-success mb-2"},[_vm._v(" "+_vm._s(_vm.fixedCurrency(_vm.bonusTotal))+" ")]),_c('div',{staticClass:"flex is-flex-wrap-wrap"},_vm._l((_vm.kpiBonus),function(ref,idx){
var bonusBehind = ref.bonusBehind;
var nextBonus = ref.nextBonus;
var total = ref.total;
var progressBar = ref.progressBar;
return _c('div',{key:idx,staticClass:"bonus-wrapper"},[_c('div',{class:{ 'pr-2': idx === 0 }},[_c('div',{staticClass:"flex is-align-items-end mb-1"},[_vm._v(" "+_vm._s(bonusBehind.text)+" "),_c('span',{staticClass:"is-size-6 has-text-success ml-1",staticStyle:{"overflow-wrap":"anywhere"}},[_vm._v(" "+_vm._s(bonusBehind.value)+" ")])]),_c('div',{class:['flex is-align-items-end has-text-grey', { 'pr-2': idx === 0 }]},[_vm._v(" "+_vm._s(((_vm.$t("admin.dashboard.mainCards.bonus.body.nextBonus")) + ":"))+" "),_c('span',{staticClass:"is-size-6 ml-1"},[_vm._v(" "+_vm._s(nextBonus)+" ")])])]),_c('div',[_c('div',{staticClass:"divider"}),_c('div',{staticClass:"flex is-align-items-end"},[_vm._v(" "+_vm._s(total.text)+" "),_c('span',{staticClass:"is-size-6 ml-1",staticStyle:{"overflow-wrap":"anywhere"}},[_vm._v(" "+_vm._s(_vm.formatEmptyString(total.value))+" ")])]),_c('div',{staticClass:"flex is-align-items-center is-flex-wrap-wrap mt-2"},[_c('span',{staticClass:"has-text-grey"},[_vm._v(" "+_vm._s(("" + (idx === 1 ? _vm.CurrencySymbol["USD"] || '' : '') + (progressBar.current) + "k"))+" ")]),_c('LTooltip',{attrs:{"label":((_vm.toFixed(progressBar.percent, 2)) + "%"),"position":"is-top","type":"is-dark"}},[_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress-bar_line",style:({ width: ((progressBar.percent) + "%") })})])]),_c('span',{staticClass:"has-text-grey"},[_vm._v(" "+_vm._s(("" + (idx === 1 ? _vm.CurrencySymbol["USD"] || '' : '') + (progressBar.next) + "k"))+" ")])],1)])])}),0),_c('LLoading',{attrs:{"is-full-page":false,"is-label-exist":false,"active":_vm.isLoading || !_vm.efficiencyKpiBonusWidget}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }