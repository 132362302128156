<template>
    <LFilters
        :filters="filters"
        :loading="loading"
        auto-confirm
        @confirm="confirmFilters">
        <template #filters>
            <div class="columns is-multiline is-real-mobile-flex">
                <FilterWrapper>
                    <CustomDatepicker v-model="datepicker"></CustomDatepicker>
                </FilterWrapper>

                <FilterWrapper>
                    <Select
                        v-model="indicator"
                        :get-data="getIndicators"
                        :placeholder="$t(`admin.dashboard.statisticChart.filters.labels.indicator`)"
                        :searchable="false"
                        :clearable="false"
                        field="label"
                        prop="value">
                    </Select>
                </FilterWrapper>
            </div>
        </template>
    </LFilters>
</template>

<script>
  import CustomDatepicker from "@/components/Common/Date/CustomDatepicker";
  import Select from "@/components/Common/Select/Select";
  import LFilters from "@/components/Common/LFilters";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import {
    UPDATE_FILTERS
  } from "@core/store/mutation-constants";
  import {
    GET_DASHBOARD_STATISTICS_CHART_WIDGET,
    SET_EMPTY
  } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";

  export default {
    name: "DashboardStatisticChartFilters",
    components: {
      CustomDatepicker,
      LFilters,
      Select
    },

    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState("admin/dashboard/dashboardStatisticWidget/dashboardStatisticChartWidget", {
        filters: ({ filters }) => filters
      }),

      ...mapFields("filtersConfirm", {
        fields: ["datepicker", "indicator"],
        base: "filters",
        action: UPDATE_FILTERS
      })
    },

    methods: {
      ...mapActions("admin/dashboard/dashboardStatisticWidget/dashboardStatisticChartWidget", {
        GET_DASHBOARD_STATISTICS_CHART_WIDGET,
        UPDATE_FILTERS,
        SET_EMPTY
      }),

      confirmFilters ({ filters }) {
        this.UPDATE_FILTERS(filters);
        this.GET_DASHBOARD_STATISTICS_CHART_WIDGET();
      },

      getIndicators () {
        const namespace = "admin.dashboard.statisticChart.filters.values";
        const groups = [
          { value: "LEADS_VALID_ACCEPTED", label: this.$t(`${ namespace }.LEADS_VALID_ACCEPTED`) },
          { value: "MONEY_COMMISSION", label: this.$t(`${ namespace }.MONEY_COMMISSION`) },
          { value: "LEADS_ACCEPTED", label: this.$t(`${ namespace }.LEADS_ACCEPTED`) },
          { value: "LEADS_SPAMMED", label: this.$t(`${ namespace }.LEADS_SPAMMED`) },
          { value: "ACTIVE_WEBMASTERS", label: this.$t(`${ namespace }.ACTIVE_WEBMASTERS`) }
        ];
        return {
          items: groups,
          count: groups.length
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .card-content {
      padding: 0 !important;
    }
  }

  .columns {
    align-items: flex-end;
  }
</style>
