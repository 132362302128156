<template>
    <Card>
        <h2 class="is-size-5 has-text-weight-bold">
            {{ $t(`admin.dashboard.statisticChart.title`) }}
        </h2>

        <ExpandFullscreen
            class="chart-modal mb-5"
            :available-orientations="['landscape-primary']"
            fixed-orientation
            start-orientation="landscape-primary">
            <DashboardStatisticChartFilters :loading="isLoading">
            </DashboardStatisticChartFilters>

            <DashboardStatisticChart :loading="isLoading">
            </DashboardStatisticChart>
        </ExpandFullscreen>
    </Card>
</template>

<script>
  import DashboardStatisticChartFilters
    from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/StatiticChart/DashboardStatisticChartFilters";
  import DashboardStatisticChart
    from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/StatiticChart/DashboardStatisticChart.vue";
  import ExpandFullscreen from "@/components/Common/Chart/ExpandFullscreen";
  import Card from "@/components/Common/Card.vue";
  import {
    GET_DASHBOARD_STATISTICS_CHART_WIDGET
  } from "@core/store/action-constants";

  export default {
    name: "DashboardStatisticChartWidget",
    components: {
      DashboardStatisticChartFilters,
      DashboardStatisticChart,
      ExpandFullscreen,
      Card
    },

    computed: {
      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatisticWidget/dashboardStatisticChartWidget/${ GET_DASHBOARD_STATISTICS_CHART_WIDGET }`);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~@/scss/dashboard";

  .card {
    height: initial;
  }
</style>
