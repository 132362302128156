<template>
    <Card :loading="isLoading">
        <div class="flex is-justify-content-space-between is-align-items-center mb-3">
            <h2 class="is-size-5 has-text-weight-bold">
                {{ title }}
            </h2>
        </div>

        <TopCardHeader
            :active-today-difference="activeTodayDifference"
            :active="activeToday"
            :user-data="userData"
            :type="type">
        </TopCardHeader>

        <div class="columns">
            <b-tabs
                v-model="activeTab"
                destroy-on-hide
                :animated="false"
                class="no-padding column"
                expanded>
                <b-tab-item
                    :key="tabItem"
                    v-for="tabItem in tabs"
                    :label="$t(`admin.dashboard.tableCards.userTopCard.tabs.amount.${ tabItem.toLowerCase() }`)">
                    <TopCardTable
                        :columns="tableColumns"
                        :data="tableData"
                        :amount-type="amountType"
                        :active-tab-index="activeTab"
                        @sort="updateSorting">
                        <template #name="{ row }">
                            <div class="has-text-left">
                                <slot
                                    name="name"
                                    :row="row">
                                    <UserOutput :user="row.group"></UserOutput>
                                </slot>
                            </div>
                        </template>
                        <template #change="{ row: { change } }">
                            <div
                                :class="getChangeColor(change[amountType].absolute)"
                                class="flex is-align-items-center is-justify-content-center">
                                <span class="mr-1">
                                    {{ getChangeAbsoluteValue(change) }}
                                </span>
                                <div>
                                    {{ getChangePercent(change[amountType].relative, change) }}
                                </div>
                            </div>
                        </template>
                        <template #graph="{ row }">
                            <sparkline
                                width="80"
                                height="30"
                                :indicator-styles="sparklineStyles.spIndicator"
                                :tooltip-props="spTooltipProps(row.graph)"
                                :margin="1"
                                :tooltip-styles="sparklineStyles.spTooltip">
                                <sparklineCurve
                                    :data="dataGraph(row.graph)"
                                    :limit="7"
                                    :styles="sparklineStyles.spCurve">
                                </sparklineCurve>
                            </sparkline>
                        </template>

                        <template #empty>
                            {{ $t(`admin.dashboard.tableCards.userTopCard.tables.amount.values.empty`) }}
                        </template>

                        <template #footer>
                            <InfiniteLoading
                                v-if="tableData.length && isInfiniteLoading_"
                                @infinite="infiniteHandler">
                                <div slot="spinner"></div>
                            </InfiniteLoading>
                        </template>
                    </TopCardTable>
                </b-tab-item>
            </b-tabs>
        </div>
    </Card>
</template>

<script>
  import TopCardHeader from "@/components/Admin/Dashboard/DashboardTops/TopCardHeader";
  import TopCardTable from "@/components/Admin/Dashboard/DashboardTops/TopCardTable";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import Card from "@/components/Common/Card";
  import { formatEmptyString, formatCurrency, formatPercent, defaultFormatter } from "@core/filters";
  import InfiniteLoading from "vue-infinite-loading";
  import sparkline from "vue-sparklines";
  import {
    GET_STATISTIC_TOP_CARD_TABLE_DATA,
    GET_ACTIVE_COUNTS,
    GET_USER_TOTAL,
    GET_STATISTIC
  } from "@core/store/action-constants";
  import {
    UPDATE_STATISTIC_SORTING,
    UPDATE_TOP_CARD_FILTERS,
    UPDATE_PAGINATION,
    SET_CURRENCY
  } from "@core/store/mutation-constants";
  import { DebounceUpdateByWatchedParams } from "@core/mixins";
  import { mapGetters, mapState } from "vuex";

  export default {
    name: "DashboardTopCard",
    mixins: [DebounceUpdateByWatchedParams],
    components: {
      TopCardHeader,
      UserOutput,
      Card,
      TopCardTable,
      sparkline,
      InfiniteLoading
    },

    props: {
      type: {
        type: String,
        required: true
      }
    },

    created () {
      this.action(SET_CURRENCY);
      if (this.enabledStatistic) {
        this.action(GET_ACTIVE_COUNTS);
        this.action(GET_USER_TOTAL);
      }
    },

    data () {
      return {
        activeTab: 1
      };
    },

    computed: {
      ...mapState({
        preferredVertical: state => state.auth.preferredVertical
      }),

      ...mapState("admin/dashboard", {
        userData (state) { return state[this.type]; },
        updateParams () { return { ...this.userData.filters }; },
        activeYesterday () { return this.userData.activeYesterday ?? 0; },
        activeToday () { return this.userData.activeToday ?? 0; },
        currency () { return this.userData.filters.currency; },
        pagination () { return this.userData.pagination; },
        amountType () { return this.userData.amountType; },
        filters () { return this.userData.filters; },
        order () { return this.userData.order; }
      }),

      ...mapGetters("admin/statistic", ["enabledStatistic"]),

      tabs () {
        return ["YESTERDAY", "TODAY", "WEEK", "MONTH"];
      },

      tableData () {
        return this.$store.getters[`admin/dashboard/${ this.type }/${ GET_STATISTIC_TOP_CARD_TABLE_DATA }`];
      },

      isInfiniteLoading_ () {
        return this.pagination.perPage * this.pagination.page < this.userData.cardStatistic?.count;
      },

      title () {
        return this.$t(`admin.dashboard.tableCards.${ this.type }.labels.title`);
      },

      isLoading () {
        const namespace = `admin/dashboard/${ this.type }`;

        return this.$wait(`${ namespace }/${ GET_USER_TOTAL }`)
          || this.$wait(`${ namespace }/${ GET_STATISTIC }`);
      },

      activeTodayDifference () {
        return formatPercent(this.activeToday / this.userData.userTotal);
      },

      tableColumns () {
        const namespace = "admin.dashboard.tableCards.userTopCard.tables";
        return [
          {
            label: this.$t(`${ namespace }.amount.labels.id`),
            field: "order",
            annotation: null
          },
          {
            label: this.$t(`admin.dashboard.tableCards.${ this.type }.table.amount.labels.name`),
            field: "name",
            annotation: null
          },
          {
            label: {
              leads: this.$t(`${ namespace }.amount.labels.amount.leads`),
              commission: this.$t(`${ namespace }.amount.labels.amount.commission`)
            },
            sort: {
              leads: "countLeadsIntegrated",
              commission: "moneyCommission"
            },
            formatter: {
              commission: (value) => formatCurrency(value.commission, this.currency)
            },
            field: "amount",
            hasSortable: true,
            annotation: null
          },
          {
            label: this.$t(`${ namespace }.amount.labels.change`),
            field: "change",
            annotation: [
              this.$t(`${ namespace }.tooltip.changeYesterday`),
              this.$t(`${ namespace }.tooltip.changeToday`),
              this.$t(`${ namespace }.tooltip.changeWeek`),
              this.$t(`${ namespace }.tooltip.changeMonth`)
            ]
          },
          {
            label: this.$t(`${ namespace }.amount.labels.graph`),
            field: "graph",
            annotation: null
          }
        ];
      },

      sparklineStyles () {
        return {
          spCurve: {
            stroke: "#54a5ff"
          },
          spIndicator: {
            stroke: "#000"
          },
          spTooltip: {
            position: "absolute",
            margin: "-7px 0 0 -150px",
            background: "rgba(0, 0, 0, 0.6)",
            borderRadius: "5px",
            minWidth: "30px",
            padding: "2px 6px",
            color: "#fff",
            fontSize: "13px"
          }
        };
      }
    },

    methods: {
      formatEmptyString,

      async action (value, params) {
        await this.$store.dispatch(`admin/dashboard/${ this.type }/${ value }`, params);
      },

      dataGraph (data) {
        return data?.map(item => {
          if (this.amountType === "leads") {
            return item.countLeadsIntegrated;
          }
          return item.moneyCommission;
        });
      },

      spTooltipProps (graph) {
        return {
          formatter (value) {
            return `${ graph[value.index].date }: ${ value.value }`;
          }
        };
      },

      async infiniteHandler ($state) {
        const pagination = {
          page: this.pagination.page + 1,
          perPage: this.pagination.perPage
        };
        await this.action(UPDATE_PAGINATION, pagination);
        await this.action(GET_STATISTIC);

        $state.loaded();
      },

      updatePeriods (tabIndex) {
        this.action(UPDATE_TOP_CARD_FILTERS, { periodType: this.tabs[tabIndex] });
      },

      async updateSorting (sort, order) {
        if (sort && order && order?.toUpperCase() !== this.order) {
          const pagination = { ...this.pagination };
          await this.action(UPDATE_STATISTIC_SORTING, { sort, order });
          try{
            await this.action(UPDATE_PAGINATION,{
              page: 1,
              perPage: this.pagination.perPage * this.pagination.page
            });
            await this.action(GET_STATISTIC);
          }
          finally {
            await this.action(UPDATE_PAGINATION, pagination);
          }
        }
      },

      async updated () {
        const pagination = { page: 1 };
        await this.action(UPDATE_TOP_CARD_FILTERS, { vertical: this.preferredVertical });
        await this.action(UPDATE_PAGINATION, pagination);
        await this.action(GET_STATISTIC);
      },

      getChangeAbsoluteValue (change) {
        const { amountType, currency } = this;

        if (amountType === "commission") {
          return formatCurrency(change[amountType].absolute, currency);
        } else {
          return defaultFormatter(change[amountType].absolute);
        }
      },

      getChangePercent (value, changeAbsoluteValue) {
        const change = this.getChangeAbsoluteValue(changeAbsoluteValue);
        return `(${ change > 0 ? "+" : change < 0 ? "-" : "" }${ value }%)`;
      },

      getChangeColor (value) {
        return value > 0 ? "has-text-success" : value < 0 ? "has-text-danger" : "";
      }
    },

    watch: {
      activeTab (tabIndex) {
        this.updatePeriods(tabIndex);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~@/scss/dashboard";

  .card {
    width: inherit;
    height: inherit;

    font-size: 1rem;
  }

  ::v-deep {
    g line {
      display: none;
    }

    .table-wrapper {
      max-height: 628px;
    }
  }
</style>
