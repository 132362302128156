<template>
    <div class="columns custom-header-padding is-multiline mt-1">
        <div class="column is-4-tablet is-12-touch">
            <ManagersSelect
                v-model="managerId"
                indicator
                :namespace-module="`admin/dashboard/${ type }/managersFilters`"
                :sort-options="{ sort: 'isBlocked', order: 'ASC' }"
                :filter-options="{ hasActiveWebmasters: 'true' }"
                :placeholder="$t(`admin.dashboard.tableCards.userTopCard.tables.amount.filters.manager`)">
            </ManagersSelect>
        </div>

        <div class="column is-4-tablet is-12-mobile">
            <Select
                v-model="currency"
                class="white"
                indicator
                :clearable="false"
                :searchable="false"
                :placeholder="$t(`admin.statistics.statistics.filters.labels.currency`)"
                :get-data="getCurrency">
            </Select>
        </div>

        <div class="column is-4-tablet is-12-mobile">
            <Select
                v-model="amountType"
                :get-data-vuex="amountTypesList"
                class="white"
                indicator
                prop="type"
                :searchable="false"
                :clearable="false">
                <template #text="{ option }">
                    {{ option.name }}
                </template>
                <template #selected-option="{ option }">
                    {{ option.name }}
                </template>
            </Select>
        </div>
    </div>
</template>

<script>
  import ManagersSelect from "@/components/Common/Select/ManagersSelect.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import {
    UPDATE_TOP_CARD_AMOUNT_TYPE,
    UPDATE_TOP_CARD_FILTERS
  } from "@core/store/mutation-constants";
  import { UsersFilters } from "@core/mixins";
  import { mapState } from "vuex";
  import { dashboard } from "@core/store/modules/admin/dashboard";
  import { useOffers } from "@/stores/admin/offers/offersStore";

  export default {
    name: "TopCardFilters",
    mixins: [UsersFilters],
    components: {
      ManagersSelect,
      Select
    },

    props: {
      type: {
        type: String,
        required: true
      },
      userData: {
        type: Object,
        required: true
      }
    },

    setup () {
      const { isShowCommissions } = useOffers();
      return { isShowCommissions };
    },

    computed: {
      dashboard () {
        return dashboard;
      },
      ...mapState("admin/dashboard", {
        adminsList: ({ adminsList }) => adminsList.data
      }),

      managerId: {
        get () {
          return this.userData.filters.managerId;
        },

        set (value) {
          this.updateOptions(value, "managerId");
        }
      },

      currency: {
        get () {
          return this.userData.filters.currency;
        },

        set (value) {
          this.updateOptions(value, "currency");
        }
      },

      amountType: {
        get () {
          return this.userData.amountType;
        },
        set (value) {
          this.updateOptions(value, "amountType", UPDATE_TOP_CARD_AMOUNT_TYPE);
        }
      }
    },

    methods: {
      updateOptions (value, key, action = UPDATE_TOP_CARD_FILTERS) {
        this.$store.dispatch(`admin/dashboard/${ this.type }/${ action }`, { [key]: value });
      },

      amountTypesList () {
        const namespace = "admin.dashboard.tableCards.userTopCard.tables.amount.labels.amount";
        const items = [
          { type: "leads", name: this.$t(`${ namespace }.leads`) },
          this.isShowCommissions && { type: "commission", name: this.$t(`${ namespace }.commission`) }
        ].filter(Boolean);
        
        return { count: items.length, items: items.filter(Boolean) };
      },

      getCurrency () {
        const items = this.currencyList;
        return {
          items,
          count: items?.length
        };
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
