<template>
    <b-table
        :data="data"
        sticky-header
        class="common-table table"
        @sort="sort">
        <b-table-column
            :key="index"
            v-for="(column, index) in columns"
            :label="getColumnLabel(column.label)"
            :sortable="column.hasSortable"
            :field="sortField(column.sort)"
            class="nowrap">
            <template
                v-if="column.annotation"
                #header>
                <LabelWithAnnotation
                    :tooltip="column.annotation[activeTabIndex]"
                    :max-width="250"
                    :flip-behavior="['left', 'bottom']"
                    tooltip-position="is-left">
                    {{ getColumnLabel(column.label) }}
                </LabelWithAnnotation>
            </template>
            <template #default="{ row, index }">
                <slot
                    :name="column.field"
                    :row="row">
                    <template v-if="column.field === 'order'">
                        {{ index + 1 }}
                    </template>
                    <template v-if="column.field === 'amount'">
                        <template v-if="amountType === 'leads'">
                            <span class="mr-1">
                                {{ defaultFormatter(row.amount.leads) }}
                            </span>
                            <LTooltip
                                :label="$t('admin.dashboard.tableCards.userTopCard.tabs.tooltip.capacity')"
                                position="is-top"
                                type="is-info">
                                <div :class="getCapacityColor(row.amount.capacity)">
                                    {{ getCapacity(row.amount.capacity) }}
                                </div>
                            </LTooltip>
                        </template>
                        <template v-if="amountType === 'commission'">
                            {{ getColumnValue(column, row) }}
                        </template>
                    </template>
                    <template v-else>
                        {{ getColumnValue(column, row) }}
                    </template>
                </slot>
            </template>
        </b-table-column>

        <template #empty>
            <div class="hero">
                <div class="hero-body">
                    <h6 class="title has-text-centered is-6">
                        <slot name="empty"></slot>
                    </h6>
                </div>
            </div>
        </template>

        <template #footer>
            <slot name="footer"></slot>
        </template>
    </b-table>
</template>

<script>
  import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation";
  import { defaultFormatter } from "@core/filters";

  export default {
    name: "TopCardTable",
    components: {
      LabelWithAnnotation
    },

    props: {
      columns: {
        type: Array,
        default: null
      },

      data: {
        type: Array,
        default: null
      },

      amountType: {
        type: String,
        default: null
      },

      activeTabIndex: {
        type: Number,
        default: null
      }
    },

    methods: {
      defaultFormatter,

      getColumnLabel (label, field) {
        if (typeof label !== "string" && field !== "amount") {
          return label[this.amountType];
        } else {
          return label;
        }
      },

      getCapacity (value) {
        return `(${ value > 0 ? "+" : "" }${ defaultFormatter(value) })`;
      },

      getCapacityColor (value) {
        return value > 0 ? "has-text-success" : value < 0 ? "has-text-danger" : "";
      },

      getValueFormatter (formatter) {
        switch (typeof formatter) {
          case "function":
            return formatter;

          case "object":
            return formatter[this.amountType];
        }
      },

      sort (value, order) {
        this.$emit("sort", value, order);
      },

      sortField (sort) {
        return sort?.[this.amountType];
      },

      getColumnValue (column, row) {
        const formatter = this.getValueFormatter(column.formatter);
        const value = row[column.field];

        return formatter ? formatter(value) : value;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~@/scss/dashboardTable";

  ::v-deep {
    table {
      thead {
        tr {
          th {
            border-radius: 0 !important;

            .th-wrap.is-relative {
              position: static !important;
            }
            .icon.sort-icon {
              right: 0 !important;
              left: auto !important;
            }
          }
        }
      }
    }
  }
</style>
