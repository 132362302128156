<template>
    <div
        :key="currentVertical"
        class="columns"
        style="overflow: auto">
        <template v-if="hasSummaryClientsWidget">
            <Permissions :permissions="['ADVERTISERS.LIST.ALL', 'ADVERTISERS.LIST.OWN', 'ADVERTISERS.LIST.UNBIND']">
                <Permissions :permissions="['DASHBOARD.SUMMARY_ADVERTISER.LIST']">
                    <div class="column card-user">
                        <DashboardTopCard type="advertisersTopCard">
                            <template #name="{ row }">
                                <AdvertiserOutput :advertiser="row.group"></AdvertiserOutput>
                            </template>
                        </DashboardTopCard>
                    </div>
                </Permissions>
            </Permissions>
            <Permissions :permissions="['WEBMASTERS.LIST.ALL', 'WEBMASTERS.LIST.OWN', 'WEBMASTERS.LIST.UNBIND']">
                <template v-if="permissionsForWebmastersCard">
                    <div class="column card-user">
                        <DashboardTopCard type="webmastersTopCard">
                            <template #name="{ row }">
                                <WebmasterOutput :webmaster="row.group"></WebmasterOutput>
                            </template>
                        </DashboardTopCard>
                    </div>
                </template>
            </Permissions>
            <Permissions :permissions="['DASHBOARD.SUMMARY_OFFER.LIST']">
                <div class="column card-user">
                    <DashboardTopCard type="offersTopCard">
                        <template #name="{ row }">
                            <CustomOfferOutput :offer="row.group"></CustomOfferOutput>
                        </template>
                    </DashboardTopCard>
                </div>
            </Permissions>
        </template>
    </div>
</template>

<script>
  import DashboardTopCard from "@/components/Admin/Dashboard/DashboardTops/DashboardTopCard";
  import AdvertiserOutput from "@/components/Common/Output/AdvertiserOutput.vue";
  import Permissions from "@/components/Common/Permissions.vue";
  import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput.vue";
  import WebmasterOutput from "@/components/Common/Output/WebmasterOutput.vue";
  import { hasPermissions } from "@core/mixins/permissions";
  import { mapActions, mapState } from "vuex";
  import {
    GET_DASHBOARD_SUMMARY_CLIENTS_WIDGET,
    SET_EMPTY
  } from "@core/store/action-constants";

  export default {
    name: "DashboardTops",
    components: {
      CustomOfferOutput,
      DashboardTopCard,
      AdvertiserOutput,
      Permissions,
      WebmasterOutput
    },

    created () {
      if (this.$route.name === "admin:dashboardAffiliate" && this.hasDashboardAffiliate) {
        this.GET_DASHBOARD_SUMMARY_CLIENTS_WIDGET();
      }
    },

    computed: {
      ...mapState({
        permissionsForWebmastersCard: ({ auth }) => hasPermissions(["DASHBOARD.SUMMARY_WEBMASTER.LIST.ALL", "DASHBOARD.SUMMARY_WEBMASTER.LIST.OWN"], auth.userPermissions),
        hasDashboardAffiliate: ({ auth }) => hasPermissions(["DASHBOARD.AFFILIATE_MANAGER.WIDGET.LIST"], auth.userPermissions),
        currentVertical: ({ verticals }) => verticals.currentVertical
      }),

      hasSummaryClientsWidget () {
        return !this.$wait(`admin/dashboard/dashboardStatisticWidget/${ GET_DASHBOARD_SUMMARY_CLIENTS_WIDGET }`);
      }
    },

    methods: {
      ...mapActions("admin/dashboard", {
        GET_DASHBOARD_SUMMARY_CLIENTS_WIDGET: `dashboardStatisticWidget/${ GET_DASHBOARD_SUMMARY_CLIENTS_WIDGET }`,
        SET_EMPTY
      })
    },

    destroyed () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .no-padding {
      padding-bottom: 0 !important;

      .tab-content {
        padding: 0 !important;
      }
    }
  }

  .card-user {
    @media screen and (min-width: 770px) {
      min-width: 600px;
    }
  }
</style>
