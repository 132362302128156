


















import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
import { defineProps, defineEmits } from "@vue/runtime-dom";
import storeInstance from "@/store";
import { computed, unref, watch } from "@vue/composition-api";
import router from "@/router";
import { useVerticals } from "@/stores/common/auth/verticalsStore";
import { storeToRefs } from "pinia";
const __sfc_main = {};
__sfc_main.props = {
  value: {
    type: String,
    default: null
  },
  showCommon: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  /** @param viewOnly Отключает функционал компонента и оставляет только визуал с одной вертикалью "Нутра"
  Требуется для улучшения очевидности в разделах, где показывается только нутра */
  viewOnly: {
    type: Boolean,
    default: false
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const store = useVerticals();
  const {
    currentVertical,
    preferredVertical,
    gamblingOnly,
    nutraOnly,
    allVerticals
  } = storeToRefs(store);
  getUserContextStorage();
  const storageFilters = computed(() => storeInstance.getters["userContextStorage/storageFilters"]);
  const role = computed(() => storeInstance.state.auth.role);
  const {
    vertical: storageVertical
  } = unref(storageFilters) || {};
  const {
    vertical: routeCurrentVertical
  } = router.currentRoute.query;
  if (props.viewOnly) {
    currentVertical.value = VerticalsEnum.NUTRA;
  } else if (storageVertical && !routeCurrentVertical || routeCurrentVertical) {
    if (storageVertical && !routeCurrentVertical) {
      currentVertical.value = storageVertical;
    } else if (routeCurrentVertical) {
      currentVertical.value = routeCurrentVertical as VerticalsEnum;
    }
  } else if (preferredVertical.value) {
    currentVertical.value = preferredVertical.value;
  }
  const verticalsList = computed(() => {
    const list = Object.values<VerticalsEnum | null>(VerticalsEnum);
    if (props.showCommon) {
      list.unshift(null);
    }
    if (allVerticals.value && !props.viewOnly) {
      return list;
    } else if (nutraOnly.value && gamblingOnly.value && !props.viewOnly || role.value !== "admin" && !props.viewOnly) {
      return [VerticalsEnum.NUTRA, VerticalsEnum.GAMBLING];
    } else if (props.viewOnly || nutraOnly.value) {
      return [VerticalsEnum.NUTRA];
    } else if (gamblingOnly.value && role.value === "admin") {
      return [VerticalsEnum.GAMBLING];
    }
    return [];
  });
  function getUserContextStorage() {
    storeInstance.commit("userContextStorage/GET_USER_CONTEXT_STORAGE", "filters");
  }
  function imageSrc(name: string) {
    return require(`@/assets/common/${name.toLowerCase()}.svg`);
  }
  function setVertical(vertical: VerticalsEnum) {
    if (!props.disabled) {
      if (props.value) {
        emit("input", vertical);
      } else {
        currentVertical.value = vertical;
      }
      emit("update");
    }
  }
  watch(() => currentVertical.value, vertical => {
    router.push({
      query: {
        ...router.currentRoute.query,
        vertical
      }
    }).catch(_ => {});
  });
  return {
    currentVertical,
    verticalsList,
    imageSrc,
    setVertical
  };
};
export default __sfc_main;
