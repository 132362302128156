var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',[_c('div',{staticClass:"flex is-justify-content-space-between is-align-items-center mb-6"},[_c('h2',{staticClass:"is-size-5"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.mainCards.incidence.title"))+" ")]),_c('router-link',{attrs:{"target":"_blank","to":{
                name: 'admin:statistics:analytic',
                query: {
                    analysisMode: true,
                    filters: _vm.encodeFilters({ managerId: _vm.managerId }),
                    backLink: _vm.$route.fullPath
                }
            }}},[_c('span',{staticClass:"has-link"},[_vm._v(" "+_vm._s(_vm.$t("common.buttons.more"))+" ")])])],1),_c('div',{staticClass:"flex is-flex-wrap-wrap mb-4"},_vm._l((_vm.incidence),function(ref,idx){
            var title = ref.title;
            var count = ref.count;
return _c('div',{key:idx,staticClass:"incidence-item"},[_c('div',{staticClass:"mb-2 pr-1"},[_c('h2',{class:[("count-card mb-4 " + (_vm.colorText(title, count)))]},[(_vm.isLoading)?_c('b-skeleton',{attrs:{"size":"is-medium","height":"33","width":"50%"}}):[_vm._v(" "+_vm._s(_vm.defaultFormatter(count))+" ")]],2),_vm._v(" "+_vm._s(_vm.$t(("admin.dashboard.mainCards.incidence.body." + title)))+" ")]),_c('p',{staticClass:"has-text-grey"},[_vm._v(" "+_vm._s(_vm.$t("common.components.datepicker.values.yesterday"))+" ")])])}),0),_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress-bar_line",style:({
                width: (_vm.percent + "%"),
                'background-color': _vm.countNotFilledIncidence > 0 ? '#ff4757' : '#46c93a'
            })})])])}
var staticRenderFns = []

export { render, staticRenderFns }