<template>
    <LLoading
        ref="wrapperCharts"
        :active="isLoading"
        :is-full-page="false">
        <div
            v-if="!hasChartData">
            <div class="hero is-medium has-text-centered">
                <div class="hero-body">
                    <h2 class="is-size-4 is-bold has-text-weight-medium has-text-grey">
                        <slot name="placeholder">
                            {{ $t(`common.table.empty`) }}
                        </slot>
                    </h2>
                </div>
            </div>
        </div>
        <v-chart
            v-else
            :option="option"
            autoresize
            class="chart">
        </v-chart>
    </LLoading>
</template>

<script>
  import LLoading from "@/components/Common/LLoading";
  import { PieChart, BarChart } from "echarts/charts";
  import { use } from "echarts/core";
  import fonts from "@/scss/_fonts.scss";
  import { THEME_KEY } from "vue-echarts";
  import { insertLineBreaks } from "@core/filters";

  use([PieChart, BarChart]);

  export default {
    name: "PieChartTemplate",
    components: { LLoading },

    provide: {
      [THEME_KEY]: "light"
    },

    props: {
      items: {
        type: Array,
        required: true
      },
      tooltipFormatter: {
        type: Function,
        required: true
      },
      legendFormatter: {
        type: Function,
        required: true
      },
      mobileWidth: {
        type: Number,
        default: 768
      },
      type: {
        type: String,
        default: "pie"
      },
      emphasisLabelFormatter: {
        type: String,
        default: "{c0}"
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      hasChartData () {
        return this.items?.length;
      },

      hasTypePie () {
        return this.type === "pie";
      },

      option () {
        const width = this.$refs.wrapperCharts?.$el.clientWidth;
        const isMobile = width <= this.mobileWidth;
        const seriesOptions = {
          type: this.type,
          width: isMobile ? 0 : "50%",
          left: "left",
          center: ["45%", "50%"],
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: this.hasTypePie ? 10 : 5,
            borderColor: this.hasTypePie && "#fff",
            borderWidth: this.hasTypePie && 2
          },
          label: {
            show: !this.hasTypePie,
            fontSize: 14,
            position: !this.hasTypePie ? "top" : "center",
            formatter: "{c}%"
          },
          emphasis: this.hasTypePie && {
            label: {
              show: !isMobile,
              fontSize: "1.3rem",
              fontWeight: "bold",
              formatter: this.emphasisLabelFormatter
            }
          },
          labelLine: {
            show: false
          },
          data: this.items
        };

        return {
          tooltip: {
            show: !isMobile,
            trigger: "item",
            formatter: (name) => this.formatter(name, width, this.tooltipFormatter),
            renderMode: "richText"
          },
          legend: {
            top: this.hasTypePie ? "center" : "top",
            right: 0,
            left: isMobile ? "0%" : this.hasTypePie ? "45%" : "left",
            orient: this.hasTypePie ? "vertical" : "horizontal",
            type: "scroll",
            icon: "circle",
            selectedMode: !isMobile,
            textStyle: {
              width: 600,
              overflow: "truncate",
              fontSize: 14,
              rich: {
                value: {
                  fontWeight: "bold",
                  fontSize: 16
                }
              }
            },
            tooltip: {
              show: !isMobile,
              formatter: this.hasTypePie ? (name) => this.formatter(name, width, this.tooltipFormatter) : null
            },
            formatter: this.hasTypePie ? (name) => this.formatter(name, width, this.legendFormatter) : null
          },
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2
          },
          textStyle: {
            fontFamily: fonts.mainFont
          },
          xAxis: !this.hasTypePie && {
            type: "category",
            axisTick: { show: false },
            nameTextStyle: {
              fontWeight: 600,
              fontSize: 2
            },
            data: (!this.hasTypePie ? this.items[0] : this.items)?.map(el => {
              const [key] = el.name?.split(" - ");
              return key;
            })
          },
          yAxis: !this.hasTypePie && {
            type: "value",
            show: false
          },

          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
          },

          series: !this.hasTypePie
            ? this.items.map(el => ({ ...seriesOptions, data: el, name: el[0].legendName }))
            : [seriesOptions]
        };
      }
    },

    methods: {
      formatter (name, width, formatter) {
        return insertLineBreaks(formatter(name), Math.trunc(width / 9));
      }
    }
  };
</script>

<style scoped lang="scss"></style>
