<template>
    <div class="mb-3">
        <div class="flex is-flex-wrap-wrap">
            <div class="mt-1">
                <span class="has-text-left is-6">
                    {{ $t(`admin.dashboard.tableCards.headerLabels.total`) }}:
                </span>
                <span class="is-6 has-text-weight-bold ml-1 mr-5">
                    <slot
                        :value="total"
                        name="total">
                        {{ defaultFormatter(total) }}
                    </slot>
                </span>
            </div>

            <div class="mt-1">
                <span class="has-text-left is-6">
                    {{ $t(`admin.dashboard.tableCards.headerLabels.active.${ hasDashboard ? 'dashboard' : 'dashboardAffiliate' }`) }}:
                </span>
                <span class="is-6 has-text-weight-bold">
                    <slot
                        :value="active"
                        name="active">
                        <span class="has-text-success ml-1">
                            {{ defaultFormatter(active) }}
                        </span>
                    </slot>
                </span>
                <span
                    v-if="!hasDashboard"
                    class="has-text-grey is-6 mr-5">
                    <LabelWithAnnotation
                        :tooltip="labelAnnotationTooltip"
                        tooltip-position="is-top"
                        type="is-info">
                    </LabelWithAnnotation>
                </span>
            </div>

            <div
                v-if="type === 'webmastersTopCard' && hasAffiliateManager"
                class="mt-1">
                <span class="has-text-left is-7">
                    {{ $t("admin.dashboard.tableCards.headerLabels.conversion") }}:
                </span>
                <span class="is-6 ml-1 has-text-weight-bold">
                    {{ defaultFormatter(activeTodayDifference, 2) }}%
                </span>
            </div>
        </div>

        <TopCardFilters
            :type="type"
            :user-data="userData">
        </TopCardFilters>
    </div>
</template>

<script>
  import TopCardFilters from "@/components/Admin/Dashboard/DashboardTops/TopCardFilters";
  import { formatEmptyString, defaultFormatter } from "@core/filters";
  import LabelWithAnnotation from "@/components/Common/Tooltip/LabelWithAnnotation.vue";

  export default {
    name: "TopCardHeader",
    components: {
      LabelWithAnnotation,
      TopCardFilters
    },
    props: {
      type: {
        type: String,
        required: true
      },

      userData: {
        type: Object,
        required: true
      },

      active: {
        type: [String, Number],
        default: null
      },

      activeTodayDifference: {
        type: [String, Number],
        default: null
      }
    },

    computed: {
      labelAnnotationTooltip () {
        return this.$t(
          `admin.dashboard.tableCards.headerLabels.${ this.type }`,
          [this.$t(`admin.dashboard.tableCards.headerLabels.active${ this.activeTitle }`)]
        );
      },

      activeTitle () {
        const { name } = this.$route;
        return name === "admin:dashboardAffiliate" ? "Month" : "Today";
      },

      hasAffiliateManager () {
        return this.$route.name === "admin:dashboardAffiliate";
      },

      total () {
        return this.userData.userTotal;
      },

      hasDashboard () {
        return this.$route.name === "dashboard";
      }
    },

    methods: {
      formatEmptyString,
      defaultFormatter
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    span .after .tippy {
      padding-left: 0 !important;
    }
  }
</style>
