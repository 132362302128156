<template>
    <Card>
        <div class="flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap">
            <h2 class="is-size-5">
                {{ $t("admin.dashboard.mainCards.bonus.header.title") }}
            </h2>

            <div class="flex is-flex-wrap-wrap">
                <template v-for="({ value }, idx) in periods">
                    <b-radio-button
                        :key="idx"
                        v-model="bonusPeriod"
                        class="ml-2"
                        :native-value="value"
                        type="is-info is-light">
                        {{ $t(`admin.dashboard.mainCards.bonus.header.${ value }`) }}
                    </b-radio-button>
                </template>
            </div>
        </div>

        <div class="is-relative">
            <p class="is-size-4 has-text-success mb-2">
                {{ fixedCurrency(bonusTotal) }}
            </p>

            <div class="flex is-flex-wrap-wrap">
                <div
                    :key="idx"
                    v-for="({ bonusBehind, nextBonus, total, progressBar }, idx) in kpiBonus"
                    class="bonus-wrapper">
                    <div :class="{ 'pr-2': idx === 0 }">
                        <div class="flex is-align-items-end mb-1">
                            {{ bonusBehind.text }}
                            <span
                                style="overflow-wrap: anywhere"
                                class="is-size-6 has-text-success ml-1">
                                {{ bonusBehind.value }}
                            </span>
                        </div>
                        <div :class="['flex is-align-items-end has-text-grey', { 'pr-2': idx === 0 }]">
                            {{ `${ $t("admin.dashboard.mainCards.bonus.body.nextBonus") }:` }}
                            <span class="is-size-6 ml-1">
                                {{ nextBonus }}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div class="divider"></div>
                        <div class="flex is-align-items-end">
                            {{ total.text }}
                            <span
                                style="overflow-wrap: anywhere"
                                class="is-size-6 ml-1">
                                {{ formatEmptyString(total.value) }}
                            </span>
                        </div>
                        <div class="flex is-align-items-center is-flex-wrap-wrap mt-2">
                            <span class="has-text-grey">
                                {{ `${ idx === 1 ? CurrencySymbol["USD"] || '' : '' }${ progressBar.current }k` }}
                            </span>
                            <LTooltip
                                :label="`${ toFixed(progressBar.percent, 2) }%`"
                                position="is-top"
                                type="is-dark">
                                <div class="progress-bar">
                                    <div
                                        class="progress-bar_line"
                                        :style="{ width: `${ progressBar.percent }%` }">
                                    </div>
                                </div>
                            </LTooltip>
                            <span class="has-text-grey">
                                {{ `${ idx === 1 ? CurrencySymbol["USD"] || '' : '' }${ progressBar.next }k` }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <LLoading
                :is-full-page="false"
                :is-label-exist="false"
                :active="isLoading || !efficiencyKpiBonusWidget">
            </LLoading>
        </div>
    </Card>
</template>

<script>
  import Card from "@/components/Common/Card.vue";
  import { mapState } from "vuex";
  import { formatCurrency, formatEmptyString, formatPercent, toFixed } from "@/filters";
  import LLoading from "@/components/Common/LLoading.vue";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_KPI_BONUS_FILTERS } from "@core/store/mutation-constants";
  import {
    GET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET
  } from "@core/store/action-constants";
  import { formatNumberToThousands, hasInfinity, defaultFormatter } from "@core/filters";
  import { CurrencySymbol } from "@core/helpers/currencySymbolMap";
  import { fixedCurrency } from "@core/flowMethods";

  export default {
    name: "DashboardBonusWidget",
    components: {
      LLoading,
      Card
    },

    computed: {
      CurrencySymbol () {
        return CurrencySymbol;
      },

      ...mapState("admin/dashboard/dashboardStatisticWidget", {
        efficiencyKpiBonusWidget: ({ efficiencyKpiBonusWidget }) => efficiencyKpiBonusWidget,
        bonusTotal: ({ efficiencyKpiBonusWidget }) => efficiencyKpiBonusWidget?.bonusTotal ?? {}
      }),

      ...mapFields("admin/dashboard/dashboardStatisticWidget", {
        fields: ["bonusPeriod"],
        base: "kpiBonusFilters",
        action: UPDATE_KPI_BONUS_FILTERS
      }),

      periods () {
        return [{ value: "previousMonth" }, { value: "currentMonth" }];
      },

      kpiBonus () {
        const {
          commission: {
            moneyCommission,
            current: currentCommission,
            currentGrade: currentGradeCommission,
            nextGrade: nextGradeCommission
          },
          lead: { 
            leads,
            current: currentLead,
            currentGrade: currentGradeLead,
            nextGrade: nextGradeLead
          }
        } = this.efficiencyKpiBonusWidget;

        return [
          {
            bonusBehind: { text: `${ this.$t("admin.dashboard.mainCards.bonus.body.bonusLeads") }:`, value: fixedCurrency(currentLead) },
            total: { text: `${ this.$t("admin.dashboard.mainCards.bonus.body.leads") }:`, value: defaultFormatter(leads) },
            nextBonus: formatCurrency(nextGradeLead.bonus, "USD"),
            progressBar: {
              current: formatNumberToThousands(currentGradeLead.count),
              next: formatNumberToThousands(nextGradeLead.count),
              percent: this.percentGoal(leads, nextGradeLead.count)
            }
          },
          {
            bonusBehind: { text: `${ this.$t("admin.dashboard.mainCards.bonus.body.commissionLeads") }:`, value: fixedCurrency(currentCommission) },
            total: { text: `${ this.$t("admin.dashboard.mainCards.bonus.body.commission") }:`, value: fixedCurrency(moneyCommission) },
            nextBonus: formatCurrency(nextGradeCommission.bonus, "USD"),
            progressBar: {
              current: formatNumberToThousands(currentGradeCommission.count),
              next: formatNumberToThousands(nextGradeCommission.count),
              percent: this.percentGoal(moneyCommission.value, nextGradeCommission.count)
            }
          }
        ];
      },

      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatisticWidget/${ GET_DASHBOARD_EFFICIENCY_KPI_BONUS_WIDGET }`);
      }
    },

    methods: {
      toFixed,
      formatEmptyString,
      defaultFormatter,
      fixedCurrency,

      percentGoal (current, next) {
        if (current && next) {
          return formatPercent(hasInfinity(current / next));
        } return 0;
      }
    }
  };
</script>

<!--suppress SassScssResolvedByNameOnly, SassScssUnresolvedVariable -->
<style lang="scss" scoped>
  @import "~@/scss/dashboard";

  .bonus-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 50%;

      @media screen and (max-width: 550px) {
          width: 100%;
      }
  }

  .progress-bar {
      width: 90px;
      height: 6px;

      margin: 0 5px;
      background-color: $light;
      border-radius: 5px;

      &_line {
          height: 100%;
          background-color: $sky-blue;
          border-radius: 5px
      }
  }

  .divider {
      height: 1px;
      background-color: $smoke;
      width: 100%;
      margin: $size-7 0;
  }
</style>
