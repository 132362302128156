<template>
    <div
        :class="{'fullGridColumns': !$saas.main}"
        class="columns statistics">
        <div
            v-if="$saas.main"
            class="column statistics-rating">
            <DashboardRatingWidget></DashboardRatingWidget>
        </div>
        <DashboardLeadStatisticWidget></DashboardLeadStatisticWidget>
        <div
            v-if="$saas.main"
            class="statistics-wrapper">
            <div class="column is-6">
                <DashboardIncidenceWidget></DashboardIncidenceWidget>
            </div>
            <div
                class="column is-6">
                <DashboardBonusWidget></DashboardBonusWidget>
            </div>
        </div>
        <div class="statistics-capacity">
            <div class="column">
                <DashboardCapacityWidget type="capacity"></DashboardCapacityWidget>
            </div>
            <div class="column">
                <DashboardCapacityWidget type="bumps"></DashboardCapacityWidget>
            </div>
        </div>
    </div>
</template>

<script>
  import DashboardLeadStatisticWidget from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/DashboardLeadStatisticWidget";
  import DashboardIncidenceWidget from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/DashboardIncidenceWidget";
  import DashboardCapacityWidget from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/DashboardCapacityWidget";
  import DashboardRatingWidget from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/DashboardRatingWidget";
  import DashboardBonusWidget from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/DashboardBonusWidget";
  import { GET_DASHBOARD_STATISTICS_WIDGET, SET_EMPTY } from "@core/store/action-constants";
  import { mapActions } from "vuex";

  export default {
    name: "DashboardWidgets",
    components: {
      DashboardLeadStatisticWidget,
      DashboardIncidenceWidget,
      DashboardCapacityWidget,
      DashboardRatingWidget,
      DashboardBonusWidget
    },

    created () {
      this.GET_DASHBOARD_STATISTICS_WIDGET();
    },

    methods: {
      ...mapActions("admin/dashboard/dashboardStatisticWidget", [
        GET_DASHBOARD_STATISTICS_WIDGET,
        SET_EMPTY
      ])
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
  .fullGridColumns {
    grid-template-columns: 100% !important;
  }
  .statistics {
    $self: &;

    display: grid;
    grid-template-columns: 25% 75% 75%;

    #{ $self }-rating {
      grid-row: 1/3;
    }

    #{ $self }-wrapper {
      display: flex;
      flex-wrap: wrap;
      grid-row: 2;
    }

    #{ $self }-capacity {
      display: flex;
      grid-column: 1/3
    }

    @media screen and (max-width: 1407px) {
      grid-template-columns: 45% 55% 75%;

      #{ $self }-wrapper, #{ $self }-rating  {
        grid-row: initial;
      }
      #{ $self }-wrapper  {
        grid-column: 3/1;
      }
    }

    @media screen and (max-width: 900px) {
      grid-template-columns: 100%;

      #{ $self }-wrapper, #{ $self }-rating, #{ $self }-capacity {
        grid-column: 1;
      }
      #{ $self }-wrapper, #{ $self }-capacity {
        display: block;

        &>.column {
          width: 100%;
        }
      }
    }
  }
</style>
