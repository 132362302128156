<template>
    <SectionWrapper
        v-if="nutra"
        is-async>
        <template #verticals>
            <VerticalsFilter view-only></VerticalsFilter>
        </template>
        <DashboardWidgets></DashboardWidgets>

        <DashboardStatisticChartWidget></DashboardStatisticChartWidget>

        <div class="columns">
            <div class="column is-6">
                <DashboardNewLaunchesWidget></DashboardNewLaunchesWidget>
            </div>
            <div class="column is-6">
                <DashboardCapacityByCountryChart></DashboardCapacityByCountryChart>
            </div>
        </div>

        <DashboardTops></DashboardTops>
    </SectionWrapper>
</template>

<script>
  import DashboardCapacityByCountryChart from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/DashboardCapacityByCountryChart";
  import DashboardNewLaunchesWidget from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/DashboardNewLaunchesWidget";
  import DashboardStatisticChartWidget
    from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/StatiticChart/DashboardStatisticChartWidget";
  import DashboardWidgets from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/DashboardWidgets";
  import DashboardTops from "@/components/Admin/Dashboard/DashboardTops";
  import SectionWrapper from "@/components/Common/SectionWrapper";
  import { mapActions, mapGetters } from "vuex";
  import {
    GET_DASHBOARD_STATISTICS,
    SET_EMPTY
  } from "@core/store/action-constants";
  import VerticalsFilter from "@/components/Common/Fields/VerticalsFilter.vue";

  export default {
    name: "DashboardAffiliateManager",
    components: {
      DashboardCapacityByCountryChart,
      DashboardStatisticChartWidget,
      DashboardNewLaunchesWidget,
      DashboardWidgets,
      VerticalsFilter,
      SectionWrapper,
      DashboardTops
    },

    computed: {
      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatistic/${ GET_DASHBOARD_STATISTICS }`);
      },

      ...mapGetters("verticals", ["nutra"])
    },

    methods: {
      ...mapActions("admin/dashboard/dashboardStatistic", {
        SET_EMPTY
      })
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
</style>
