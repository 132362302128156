<template>
    <span class="nowrap has-text-left user-wrapper">
        <MiniCardTooltip
            v-if="activeDropdown && advertiserId"
            :is-loading="isLoading"
            :current-item="item"
            @onShow="onShow">
            <template #image>
                <img
                    alt="avatar"
                    :class="{ 'avatar-external': avatar }"
                    :src="hasAvatar">
            </template>

            <template #content>
                <div class="card-profile">
                    <div class="card-profile_header">
                        <div class="is-align-items-center">
                            <img
                                :src="logo"
                                alt=""
                                class="card-profile_header_avatar">
                            <span class="card-profile_header_name">
                                {{ item.login }}
                            </span>
                            <span class="card-profile_header_name pl-1">
                                [{{ item.intId }}]
                            </span>
                        </div>
                    </div>

                    <div class="divider"></div>

                    <div class="card-profile_body">
                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.advertiserCard.miniCard.admin') }:` }}
                            </span>
                            <UserOutput :user="item.admin"></UserOutput>
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.advertiserCard.miniCard.offersAmount') }:` }}
                            </span>
                            {{ item.offersAmount }}
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.contacts') }:` }}
                            </span>
                            <UserContacts :contacts="item.contact">
                            </UserContacts>
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name">
                                {{ `${ $t('common.entity.advertiserCard.miniCard.systemVertical') }:` }}
                            </span>
                            {{ translateVertical(item.systemVertical) }}
                        </div>

                        <div class="card-profile_body_content">
                            <span class="card-profile_body_content_name is-align-items-flex-start">
                                {{ `${ $t('common.entity.advertiserCard.miniCard.registeredAt') }:` }}
                            </span>
                            <div class="flex is-flex-direction-column">
                                {{ momentWithEmpty(item.activity.registeredAt, "DD.MM.YYYY") }}
                                <span
                                    class="has-text-grey has-text-centered"
                                    style="font-size: 12px">
                                    {{ momentFrom(item.activity.registeredAt) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </MiniCardTooltip>

        <CanCopy
            :value="canCopy"
            :prevent-copy="!copy">
            <UserOutput
                :user="advertiser"
                :login="login">
            </UserOutput>
        </CanCopy>
    </span>
</template>

<script>
  import MiniCardTooltip from "@/components/Common/Tooltip/MiniCardTooltip.vue";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";
  import CanCopy from "@/components/Common/Tooltip/CanCopy.vue";
  import { GET_ADVERTISER_BY_ID } from "@core/store/action-constants";
  import { momentWithEmpty } from "@core/flowMethods";
  import { formatEmptyString, momentFrom } from "@core/filters";
  import { mapActions, mapState } from "vuex";
  import _startCase from "lodash/startCase";
  import UserContacts from "@/components/Common/Output/UserContacts.vue";

  export default {
    name: "AdvertiserOutput",
    components: {
      UserContacts,
      MiniCardTooltip,
      UserOutput,
      CanCopy
    },
    props: {
      position: {
        type: String,
        default: "is-bottom"
      },
      advertiser: {
        type: Object,
        default: null
      },
      activeDropdown: {
        type: Boolean,
        default: true
      },
      copy: {
        type: Boolean,
        default: true
      },
      login: {
        type: Boolean,
        default: false
      },
      avatar: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState("admin/advertiserDataById", {
        data: ({ data }) => data
      }),

      item () {
        const { advertiserId, data } = this;
        return data?.[advertiserId];
      },

      logo () {
        return this.item?.avatar?.url ?? require("@/assets/Avatar.svg");
      },

      isLoading () {
        return this.$wait(`admin/advertiserDataById/${ GET_ADVERTISER_BY_ID }`);
      },

      advertiserId () {
        return this.advertiser?.id;
      },

      avatarExternal () {
        return this.advertiser?.avatar?.url ?? require("@/assets/Avatar.svg");
      },

      hasAvatar () {
        return this.avatar ? this.avatarExternal : require("@/assets/Advertiser.svg");
      },

      canCopy () {
        const advertiser = this.advertiser;
        if (advertiser && advertiser.login && advertiser.intId) {
          return `${ advertiser.login } ${ advertiser.intId }`;
        } return null;
      }
    },

    methods: {
      formatEmptyString,
      momentWithEmpty,
      momentFrom,

      ...mapActions("admin/advertiserDataById", {
        GET_ADVERTISER_BY_ID
      }),

      onShow () {
        const { advertiserId, activeDropdown, item, isLoading } = this;

        if (!item && advertiserId && !isLoading && activeDropdown) {
          this.GET_ADVERTISER_BY_ID(advertiserId);
        }
      },

      translateVertical (vertical) {
        return vertical ? this.$t(`dictionaries.verticals.${ _startCase(vertical.toLowerCase()) }`) : "-";
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "~@/scss/user-mini-card";
</style>
