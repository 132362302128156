<template>
    <div
        class="flex is-flex-wrap-wrap"
        v-bind="$attrs">
        <template v-for="(el, idx) in datesEnumOrPeriods">
            <b-radio-button
                :key="idx"
                v-model="_value"
                :class="['my-1', { 'ml-2': idx !== 0 }]"
                :native-value="el"
                :type="buttonType">
                {{ $t(`common.components.datepicker.values.${ el }`) }}
            </b-radio-button>
        </template>
    </div>
</template>

<script>
  import { datePickerPeriods } from "@core/mixins/datePickerPeriods";
  import momentjs from "moment/moment";
  import { momentToDate } from "@core/helpers/momentToDate";
  import { serializeDate } from "@core/helpers/serializeDate";

  export default {
    name: "PeriodButtons",
    mixins:[datePickerPeriods],

    props: {
      value: {
        type: null,
        required: true
      },
      buttonType: {
        type: String,
        default: "is-info is-light"
      },
      periodsEnum: {
        type: Array,
        default: null
      }
    },

    computed: {
      _value: {
        get () {
          if (this.periodsEnum) {
            return this.value;
          }
          return this.period;
        },
        set (value) {
          if (this.periodsEnum) {
            return this.$emit("input", value);
          }

          const [dateStart, dateEnd] = this[value];
          this.$emit("input", {
            dateStart: serializeDate(momentToDate(momentjs(dateStart, "YYYY-MM-DD").startOf("day"))),
            dateEnd: serializeDate(momentToDate(momentjs(dateEnd, "YYYY-MM-DD").endOf("day")))
          });
        }
      },

      datesEnumOrPeriods () {
        return this.periodsEnum ?? this.periods;
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
