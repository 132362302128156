<template>
    <Card>
        <div class="flex is-justify-content-space-between is-align-items-center mb-6">
            <h2 class="is-size-5">
                {{ $t(`admin.dashboard.mainCards.capacity.title.${ type }`) }}
            </h2>

            <router-link
                target="_blank"
                :to="{
                    name: `admin:capacityAndBumps:${ type === 'bumps' ? 'statistics' : 'efficiency' }`,
                    query: { backLink: $route.fullPath }
                }">
                <span class="has-link">
                    {{ $t("common.buttons.more") }}
                </span>
            </router-link>
        </div>

        <div class="flex is-flex-wrap-wrap mb-4">
            <div
                :key="idx"
                v-for="(el, idx) in data"
                :style="{ width: `${ 100 / data.length }%` }"
                class="capacity-item">
                <div class="flex">
                    <div class="mb-2 pr-1">
                        <div :class="['flex count-card mb-4', { 'has-text-success': idx === 1 }]">
                            <b-skeleton
                                v-if="isLoading"
                                size="is-medium"
                                height="40"
                                width="70px">
                            </b-skeleton>
                            <template v-else>
                                {{ defaultFormatter(el.count) }}
                                <b-tag
                                    v-if="el.percent"
                                    class="ml-2 mt-3"
                                    :type="`is-${ el.percent < 0 ? 'danger' : 'success' } is-light`">
                                    {{ `${ el.percent }%` }}
                                </b-tag>
                            </template>
                        </div>
                        {{ $t(`admin.dashboard.mainCards.capacity.body.${ el.title }`) }}
                    </div>
                </div>
                <p class="has-text-grey">
                    {{ $t(`admin.dashboard.mainCards.capacity.body.${ el.subtitle || 'thirtyDays' }`) }}
                </p>
            </div>
        </div>
    </Card>
</template>

<script>
  import Card from "@/components/Common/Card.vue";
  import { mapState } from "vuex";
  import {
    GET_DASHBOARD_CAPACITY_WIDGET,
    GET_DASHBOARD_PAYOUT_WIDGET
  } from "@core/store/action-constants";
  import { formatPercent, defaultFormatter } from "@core/filters";
  import { hasInfinity } from "@core/filters";

  export default {
    name: "DashboardCapacityWidget",
    components: { Card },

    props: {
      type: {
        type: String,
        required: true
      }
    },

    computed: {
      ...mapState("admin/dashboard/dashboardStatisticWidget", {
        capacityRequestWidget: ({ capacityRequestWidget }) => capacityRequestWidget,
        payoutRequestWidget: ({ payoutRequestWidget }) => payoutRequestWidget
      }),

      data () {
        const { capacityRequestWidget, payoutRequestWidget, type } = this;
        const dataSet = { capacity: capacityRequestWidget, bumps: payoutRequestWidget };

        const {
          countApprovedRequests, countRequests, countPendingRequests, countEffectiveRequests
        } = dataSet[type] ?? {};

        const data = {
          capacity: { toDay: countRequests, approve: countApprovedRequests, pending: countPendingRequests },
          bumps: { toDay: countRequests, approve: countApprovedRequests, efficiency: countEffectiveRequests, pending: countPendingRequests }
        }[type];

        let items = [
          { count: data.pending, title: "wait", subtitle: "toDay" },
          { count: data.approve, percent: defaultFormatter(formatPercent(hasInfinity(data.approve / data.toDay)), 2), title: "approve" }
        ];

        if (this.type === "bumps") {
          items = [
            ...items,
            {
              count: data.efficiency,
              percent: defaultFormatter(formatPercent(hasInfinity(data.efficiency / data.toDay)), 2),
              title: "efficiency"
            }
          ];
        }

        return items;
      },

      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatisticWidget/${ GET_DASHBOARD_CAPACITY_WIDGET }`) ||
          this.$wait(`admin/dashboard/dashboardStatisticWidget/${ GET_DASHBOARD_PAYOUT_WIDGET }`);
      }
    },

    methods: {
      defaultFormatter,
      formatPercent
    }
  };
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss" scoped>
  @import "~@/scss/dashboard";

  .capacity-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 550px) {
      width: 50%;
    }
  }
</style>
