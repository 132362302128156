<template>
    <Card>
        <div class="flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-center mb-6">
            <h2 class="is-size-5 has-text-weight-bold mb-2">
                {{ $t("admin.dashboard.mainCards.capacityByCountry.title") }}
            </h2>

            <div class="flex is-flex-wrap-wrap">
                <CustomPeriod
                    v-model="datepicker"
                    :periods="['yesterday', 'today', 'week', 'month']">
                </CustomPeriod>
            </div>
        </div>

        <PieChartTemplate
            :items="items"
            :is-loading="isLoading"
            :legend-formatter="legendFormatter"
            :tooltip-formatter="tooltipFormatter"
            :mobile-width="400">
        </PieChartTemplate>
    </Card>
</template>

<script>
  import PieChartTemplate from "@/components/Common/Chart/PieChartTemplate";
  import CustomPeriod from "@/components/Common/PeriodButtons";
  import Card from "@/components/Common/Card";
  import { mapState } from "vuex";
  import { GET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET } from "@core/store/action-constants";
  import { UPDATE_CAPACITY_BY_COUNTRY_FILTERS } from "@core/store/mutation-constants";
  import { formatEmptyString, formatPercent } from "@/filters";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { defaultFormatter } from "@core/filters";

  export default {
    name: "DashboardCapacityByCountryChart",
    components: {
      PieChartTemplate,
      CustomPeriod,
      Card
    },

    computed: {
      ...mapState("admin/dashboard/dashboardStatisticWidget",{
        data: ({ capacityByCountryWidget }) => capacityByCountryWidget
      }),

      ...mapFields("admin/dashboard/dashboardStatisticWidget", {
        fields: ["datepicker"],
        base: "capacityByCountryFilters",
        action: UPDATE_CAPACITY_BY_COUNTRY_FILTERS
      }),

      items () {
        return this.data?.map(item => {
          const { countries, capacity } = item;
          const countriesName = countries.map(el => el.name).join(", ");
          const length = countries.length - 1 ? `+${ countries.length - 1 }` : "";

          const name = `${ countriesName } - ${ countries[0].name } ${ length } - ${ defaultFormatter(capacity) }`;
          return {
            name,
            value: capacity
          };
        }).sort((a,b) => b.value - a.value) ?? [];
      },

      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatisticWidget/${ GET_DASHBOARD_CAPACITY_BY_COUNTRY_WIDGET }`);
      }
    },

    methods: {
      formatEmptyString,
      formatPercent,

      tooltipFormatter (value) {
        const name = typeof value === "string" ? value : value.name;
        const [countriesName, _, capacity] = name.split(" - ");
        return `${ countriesName }  -  ${ capacity }`;
      },

      legendFormatter (name) {
        const [_, country, capacity] = name.split(" - ");
        return `{value|${ country }}  -  ${ capacity }`;
      }
    }
  };
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss" scoped>
  @import "~@/scss/dashboard";

  ::v-deep {
    .chart {
      min-height: 300px;
    }
  }
</style>
