<template>
    <Card>
        <h2 class="is-size-5 mb-5">
            {{ $t(`admin.dashboard.mainCards.leadsStatistic.${ title }`) }}
        </h2>

        <b-skeleton
            v-if="isLoading || !data"
            size="is-medium"
            height="44"
            width="50%">
        </b-skeleton>
        <p
            v-else
            class="count-card mb-1">
            {{ typeof data.after === "number" ? defaultFormatter(data.after) : data.after }}
        </p>

        <p class="has-text-grey mb-1">
            {{ $t("common.components.datepicker.values.today") }}
        </p>
        <b-tag
            :type="`is-${ tagColor } is-light`">
            {{ `${ fixedFormatPercent(data && data.diff) }%` }}
        </b-tag>
    </Card>
</template>

<script>
  import Card from "@/components/Common/Card.vue";
  import { formatEmptyString } from "@/filters";
  import {
    GET_DASHBOARD_LEAD_STATISTIC_WIDGET
  } from "@core/store/action-constants";
  import { fixedFormatPercent } from "@core/flowMethods";
  import { defaultFormatter } from "@core/filters";

  export default {
    name: "DashboardCard",
    components: { Card },

    props: {
      data: {
        type: Object,
        default: () => {}
      },
      title: {
        type: String,
        default: ""
      }
    },

    computed: {
      tagColor () {
        const diff = this.data?.diff;
        return (this.title === "spam" ? diff > 0 : diff < 0) ? "danger" : "success";
      },

      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatisticWidget/${ GET_DASHBOARD_LEAD_STATISTIC_WIDGET }`);
      }
    },

    methods: {
      fixedFormatPercent,
      formatEmptyString,
      defaultFormatter
    }
  };
</script>

<style lang="scss" scoped>
    @import "~@/scss/dashboard";

    .count-card {
      font-size: 1.5rem;
    }
</style>
