<template>
    <DashboardAffiliateManager></DashboardAffiliateManager>
</template>

<script>
  import DashboardAffiliateManager from "@/components/Admin/Dashboard/DashboardAffiliateManager.vue";

  export default {
    name: "DashboardAffiliate",
    components: { DashboardAffiliateManager }
  };
</script>

<style scoped>

</style>
