<template>
    <div class="leads-statistic">
        <template v-for="(el, idx) in dashboardCards">
            <div
                :key="idx"
                class="column is-6 is-6-real-mobile is-3-fullhd">
                <DashboardCard
                    :title="el.title"
                    :data="el.data">
                </DashboardCard>
            </div>
        </template>
    </div>
</template>

<script>
  import DashboardCard from "@/components/Admin/Dashboard/DashboardAffiliate/Widgets/DashboardCard";
  import { mapState } from "vuex";
  import { fixedCurrency } from "@core/flowMethods";

  export default {
    name: "DashboardLeadStatisticWidget",
    components: { DashboardCard },

    computed: {
      ...mapState("admin/dashboard/dashboardStatisticWidget", {
        leadStatisticWidget: ({ leadStatisticWidget }) => leadStatisticWidget
      }),

      preferredCurrency () {
        return this.$store.state.auth.preferredCurrency;
      },

      dashboardCards () {
        let {
          countLeadsValidAccepted, moneyLeadCommission,
          countLeadsSpamByAdvertiser, countLeadsAccepted
        } = this.leadStatisticWidget ?? {};

        const preferredCurrencyFilter = el => el.currency === this.preferredCurrency;

        countLeadsValidAccepted = countLeadsValidAccepted?.filter(preferredCurrencyFilter)?.[0];
        countLeadsSpamByAdvertiser = countLeadsSpamByAdvertiser?.filter(preferredCurrencyFilter)?.[0];
        countLeadsAccepted = countLeadsAccepted?.filter(preferredCurrencyFilter)?.[0];
        moneyLeadCommission = moneyLeadCommission?.filter(el => el.after.currency === this.preferredCurrency)?.[0];

        moneyLeadCommission = {
          ...moneyLeadCommission,
          after: moneyLeadCommission?.after ? fixedCurrency(moneyLeadCommission.after) : null
        };

        return [
          { title: "leads", data: countLeadsValidAccepted },
          { title: "commission", data: moneyLeadCommission },
          { title: "spam", data: countLeadsSpamByAdvertiser },
          { title: "approve", data: countLeadsAccepted }
        ];
      }
    }
  };
</script>

<style lang="scss" scoped>
    .leads-statistic {
        display: flex;
        flex-wrap: wrap;

        @media screen and (max-width: 550px) {
            display: block;
        }
    }
</style>
