<template>
    <Card>
        <h2 class="has-text-weight-bold is-size-4 has-text-centered mb-2">
            {{ $t("admin.dashboard.mainCards.rating") }}
        </h2>

        <div class="rating is-relative">
            <LLoading
                v-if="isLoading"
                :is-full-page="false"
                :is-label-exist="false"
                :active="isLoading">
            </LLoading>
            <span
                v-else-if="hasEmpty"
                class="flex is-justify-content-center is-align-items-center is-size-5">
                {{ $t("admin.dashboard.mainCards.noData") }}
            </span>
            <template v-else>
                <div
                    :key="idx"
                    v-for="({ rateNumber, admin, isMe }, idx) in efficiencyRateWidget"
                    class="rating-item">
                    <img
                        alt="Avatar"
                        :src="avatar(admin)">
                    <span class="ml-2">
                        {{ `${ rateNumber }. ${ isMe
                            ? $t('common.table.self')
                            : `${ admin.login } [${ admin.intId }]` }`
                        }}
                    </span>
                </div>
            </template>
        </div>
    </Card>
</template>

<script>
  import LLoading from "@/components/Common/LLoading.vue";
  import Card from "@/components/Common/Card.vue";
  import { GET_DASHBOARD_EFFICIENCY_RATE_WIDGET } from "@core/store/action-constants";
  import { mapState } from "vuex";

  export default {
    name: "DashboardRatingWidget",
    components: { LLoading, Card },

    computed: {
      ...mapState("admin/dashboard/dashboardStatisticWidget", {
        efficiencyRateWidget: ({ efficiencyRateWidget }) => efficiencyRateWidget
      }),

      hasEmpty () {
        return this.efficiencyRateWidget?.length === 0;
      },

      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatisticWidget/${ GET_DASHBOARD_EFFICIENCY_RATE_WIDGET }`);
      }
    },

    methods: {
      avatar (item) {
        return item.avatar?.url ?? require("@/assets/Avatar.svg");
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~@/scss/dashboard";

  .rating {
    height: calc(100% - 44px);
    overflow: auto;

    .rating-item {
      //$indent: 5px;

      display: flex;
      align-items: center;

      height: calc(14.285% - 5px);

      padding: 12px 16px;
      margin-bottom: 5px;

      border: 1px solid #E9E9E9;
      border-radius: .75rem;

      & img {
        $size: 24px;

        width: $size;
        height: $size;
        border-radius: 50%;
      }
    }
  }
</style>
