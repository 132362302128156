var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',[_c('div',{staticClass:"flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-center mb-6"},[_c('h2',{staticClass:"is-size-5 has-text-weight-bold mb-2"},[_vm._v(" "+_vm._s(_vm.$t("admin.dashboard.mainCards.newLaunches.title"))+" ")]),_c('div',{staticClass:"flex"},[_c('CustomPeriod',{attrs:{"periods":['yesterday', 'today', 'week', 'month']},model:{value:(_vm.datepicker),callback:function ($$v) {_vm.datepicker=$$v},expression:"datepicker"}})],1)]),_c('TablePlaceholder',{attrs:{"data":_vm.data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
var count = ref.count;
return [_c('LTable',{attrs:{"data":items,"loading":_vm.isLoading,"count":count,"hide-pagination":""},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.hasItems && _vm.isInfiniteLoading_)?_c('InfiniteLoading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"})]):_vm._e()]},proxy:true}],null,true)},[_c('b-table-column',{attrs:{"label":_vm.$t("common.entity.filters.webmaster")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('WebmasterOutput',{attrs:{"webmaster":_vm.user(row)}})]}}],null,true)}),_c('b-table-column',{attrs:{"label":_vm.$t("common.entity.filters.offer")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('CustomOfferOutput',{attrs:{"offer":_vm.offer(row)}})]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }