<template>
    <CommonChartTemplate
        :datasets="datasetsFields"
        full-screen-mode
        :loading="loading"
        :tooltip-formatter="tooltipFormatter"
        :legend-options="legendOptions"
        :x-labels="xLabels">
    </CommonChartTemplate>
</template>

<script>
  import { chartColors } from "@/components/Charts/chart.config";
  import { tooltipBuilder } from "@/components/Charts/tooltipBuilder";
  import CommonChartTemplate from "@/components/Common/Chart/CommonChartTemplate.vue";
  import { defaultFormatter, formatCurrency, moment } from "@core/filters";
  import { mapState } from "vuex";
  import { IndicatorEnum } from "@core/store/types/admin/dashboard/enums/indicatorEnum";

  export default {
    name: "DashboardStatisticChart",
    components: { CommonChartTemplate },

    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapState("admin/dashboard/dashboardStatisticWidget/dashboardStatisticChartWidget", {
        data: ({ statisticsChartWidget }) => statisticsChartWidget,
        indicator: ({ filters }) => filters.indicator
      }),

      legendOptions () {
        return {
          show: false
        };
      },

      approve () {
        return this.data.map(item => item?.conversionApprove);
      },

      xLabels () {
        if (!this.data) return [];

        return this.data.map((item) => moment(item.date, "DD MMM YYYY"));
      },

      datasetsFields () {
        const { labels } = this.tooltip;

        const dataSet = {
          ACTIVE_WEBMASTERS: "activeWebmaster",
          MONEY_COMMISSION: "commission",
          LEADS_VALID_ACCEPTED: "leads",
          LEADS_ACCEPTED: "approve",
          LEADS_SPAMMED: "spam"
        };

        return [
          {
            name: dataSet[this.indicator],
            label: labels[dataSet[this.indicator]],
            color: chartColors.default.info,
            backgroundColor: chartColors.default.infoFill,
            data: this.data?.map((item) => item.value.count ?? item.value)
          }
        ];
      },

      tooltip () {
        return {
          labels: {
            leads: this.$t("admin.dashboard.statisticChart.filters.values.LEADS_VALID_ACCEPTED"),
            commission: this.$t("admin.dashboard.statisticChart.filters.values.MONEY_COMMISSION"),
            approve: this.$t("admin.dashboard.statisticChart.filters.values.LEADS_ACCEPTED"),
            spam: this.$t("admin.dashboard.statisticChart.filters.values.LEADS_SPAMMED"),
            activeWebmaster: this.$t("admin.dashboard.statisticChart.filters.values.ACTIVE_WEBMASTERS")
          },
          formatters: {
            commission: el => formatCurrency(el, "USD")
          }
        };
      }
    },

    methods: {
      tooltipFormatter (seriesName, pinSeriesName) {
        const { labels, formatters } = this.tooltip;
        return (params) => tooltipBuilder(params, labels, formatters, seriesName, pinSeriesName);
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
