<template>
    <Card>
        <div class="flex is-justify-content-space-between is-align-items-center mb-6">
            <h2 class="is-size-5">
                {{ $t("admin.dashboard.mainCards.incidence.title") }}
            </h2>

            <router-link
                target="_blank"
                :to="{
                    name: 'admin:statistics:analytic',
                    query: {
                        analysisMode: true,
                        filters: encodeFilters({ managerId }),
                        backLink: $route.fullPath
                    }
                }">
                <span class="has-link">
                    {{ $t("common.buttons.more") }}
                </span>
            </router-link>
        </div>

        <div class="flex is-flex-wrap-wrap mb-4">
            <div
                :key="idx"
                v-for="({ title, count }, idx) in incidence"
                class="incidence-item">
                <div class="mb-2 pr-1">
                    <h2 :class="[`count-card mb-4 ${ colorText(title, count) }`]">
                        <b-skeleton
                            v-if="isLoading"
                            size="is-medium"
                            height="33"
                            width="50%">
                        </b-skeleton>
                        <template v-else>
                            {{ defaultFormatter(count) }}
                        </template>
                    </h2>
                    {{ $t(`admin.dashboard.mainCards.incidence.body.${ title }`) }}
                </div>
                <p class="has-text-grey">
                    {{ $t("common.components.datepicker.values.yesterday") }}
                </p>
            </div>
        </div>
        <div class="progress-bar">
            <div
                class="progress-bar_line"
                :style="{
                    width: `${ percent }%`,
                    'background-color': countNotFilledIncidence > 0 ? '#ff4757' : '#46c93a'
                }">
            </div>
        </div>
    </Card>
</template>

<script>
  import Card from "@/components/Common/Card.vue";
  import { mapState } from "vuex";
  import { GET_DASHBOARD_INCIDENCE_WIDGET } from "@core/store/action-constants";
  import { encodeFilters, hasInfinity, defaultFormatter } from "@core/filters";
  import { fixedFormatPercent } from "@core/flowMethods";

  export default {
    name: "DashboardIncidenceWidget",
    components: { Card },

    computed: {
      ...mapState("admin/dashboard/dashboardStatisticWidget", {
        countNotFilledIncidence: ({ incidenceWidget }) => incidenceWidget?.countNotFilledIncidence,
        incidenceWidget: ({ incidenceWidget }) => incidenceWidget
      }),

      ...mapState("admin/profile", {
        managerId: ({ user: { id } }) => id
      }),

      incidence () {
        const {
          countNotFilledIncidence, totalIncidence,
          countWebmaster, countOffer
        } = this.incidenceWidget ?? {};

        return [
          { count: totalIncidence, title: "total" },
          { count: countNotFilledIncidence, title: "notFilled" },
          { count: countOffer, title: "offers" },
          { count: countWebmaster, title: "webmasters" }
        ];
      },

      percent () {
        const { totalIncidence, countNotFilledIncidence } = this.incidenceWidget ?? {};
        const percent = countNotFilledIncidence === 0 ? 1 : countNotFilledIncidence / totalIncidence;

        return fixedFormatPercent(hasInfinity(percent));
      },

      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatisticWidget/${ GET_DASHBOARD_INCIDENCE_WIDGET }`);
      }
    },

    methods: {
      colorText (title, count) {
        return title === "notFilled" ? `has-text-${ count > 0 ? "danger" : "success" }` : "";
      },

      defaultFormatter,
      encodeFilters
    }
  };
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss" scoped>
  @import "~@/scss/dashboard";

  .progress-bar {
    width: 100%;
    height: 6px;

    background-color: $light;
    border-radius: 10px;

    &_line {
      height: 100%;
      border-radius: 5px
    }
  }

  .incidence-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    width: 25%;

    @media screen and (max-width: 550px) {
      width: 50%;
    }
  }
</style>
