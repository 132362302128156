import { render, staticRenderFns } from "./DashboardCapacityWidget.vue?vue&type=template&id=2bc1ceb6&scoped=true"
import script from "./DashboardCapacityWidget.vue?vue&type=script&lang=js"
export * from "./DashboardCapacityWidget.vue?vue&type=script&lang=js"
import style0 from "./DashboardCapacityWidget.vue?vue&type=style&index=0&id=2bc1ceb6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc1ceb6",
  null
  
)

export default component.exports